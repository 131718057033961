export default (formRef, useReset = true) => {
    const alertBoxStore = useAlertBoxStore();
    const isLoading = ref(false);
    const isSuccess = ref(false);

    /*
        Form Reset
    */
    watch(() => isSuccess.value, (newValue) => {
        if (newValue) {
            if (!useReset) return;
            const form = formRef.value.querySelector('.form-controller');
            if (!form) return;
            form.reset();
        }
    });

    /*
        Helper function to sanitize input
    */
    const _sanitize = (string) => string.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#x27;')
        .replace(/\//g, '&#x2F;');

    const sanitize = (values) => {
        const sanitized = values;
        Object.entries(sanitized).forEach((entry) => {
            const [key, value] = entry;

            if (typeof value === 'string') {
                sanitized[key] = _sanitize(value);
            }
        });

        return sanitized;
    };

    /*
        Send
        @param callback - The function you want to use as callback
        @param values - the values of the form inlcuding success and error messages
        @param useAlertBox - if true, an overlay opens with the result to inform the user
    */
    const send = async (callback, values, redirect = false) => {
        isLoading.value = true;

        const { statusData } = values;

        const submitValues = sanitize(values);
        delete submitValues.statusData;

        try {
            await callback();
            if (statusData) alertBoxStore.setAlertBoxData('success', statusData);

            if (redirect) {
                navigateTo(redirect);
            }

            isSuccess.value = true;
        } catch (e) {
            if (statusData) alertBoxStore.setAlertBoxData('error', statusData);
            useSentryError(e);
            isSuccess.value = false;
        } finally {
            if (statusData) { alertBoxStore.toggleAlertbox(); }
            isLoading.value = false;
        }
    };

    return {
        isLoading,
        isSuccess,
        sanitize,
        send,
    };
};
